import React, {lazy} from 'react';
import './App.css';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import Loader from './assets/loader.gif'
import 'antd/dist/antd.css';
import { isMobile } from 'react-device-detect';

const Home = lazy(() => import('./views/Home'));
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route  path="/" element={
            <React.Suspense fallback={<img className={isMobile?'loader-mobile':''} src={Loader}/>}>
              <Home/>
            </React.Suspense>
          }>
          </Route>
          <Route  path="/results" element={
            <React.Suspense fallback={<><img src={Loader}/></>}>
              <Home results={true}/>
            </React.Suspense>
          }>
          </Route>
        </Routes>
    </Router>
    </div>
  );
}

export default App;
