import {combineReducers} from 'redux'
import {
    SET_QASAID_SEARCH_FIELD,
    SET_QASAID_SEARCH_TERM,
    SEARCH_QASAID,
    SET_SEARCH_TERM,
    SET_SEARCH_CATEGORY,
    LOGO_URL,
    FETCH_COMBOBOXVALUES,
    SET_QASAID,
    READ_FILE_NAMES,
    READ_AUDIO_FILES,
    SET_TAB_VALUE,
    READ_AUDIO_DIR,
    GET_SORTED_RESULTS,
    SET_SEARCH_PARAMETERS,
    GET_NOHA_DIRECTORY,
    GET_NOHA_MP3,
    SET_MP3_PATH,
    GET_TASSAWAR_PAK_DIRECTORY,
    GET_TASSAWAR_PAK,
    SET_TASSAWAR_PAK_PATH,
    GET_YT_JSON,
    SET_ADV_SEARCH_EXPAND_VALUE,
    READ_BOOKLET_NAMES
} from './actions';

//------------------------------------------------------------
export const qasaidDataReducer = (state = [], action) => {
    
    const { type, payload } = action;
    switch (type) {
    case SEARCH_QASAID: {
        
        return payload
    }
    case SET_QASAID:{
        return payload
    }
    case GET_SORTED_RESULTS:{
        return payload
    }
    default:
        return state;
    }
}

export const qasaidSearchTermReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_QASAID_SEARCH_TERM:{
            return payload
        }
        default:
            return state;
    }
}

export const ytJsonReducer=(state={"before2003":[{"name":"","id":""}],"after2003":[{"name":"","id":""}]},action)=>{
    const {type,payload}=action;
    switch(type){
        case GET_YT_JSON:{
            return payload
        }
        default:
            return state;
    }
}

export const searchTermReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_SEARCH_TERM:{
            return payload
        }
        default:
            return state;
    }
}

export const qasaidSearchFieldReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_QASAID_SEARCH_FIELD:{
            return payload
        }
        default:
            return state;
    }
}

export const searchCatergoryReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_SEARCH_CATEGORY:{
            return payload
        }
        default:
            return state;
    }
}


export const getComboxvaluesReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case FETCH_COMBOBOXVALUES:{
            return payload
        }
        default:
            return state;
    }
}


export const setLogo=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case LOGO_URL:{
            return payload
        }
        default:
            return state;
    }
}

export const setMp3Path=(state='test',action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_MP3_PATH:{
            return payload
        }
        default:
            return state;
    }
}

export const setTassawarPakPathReducer=(state='test',action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_TASSAWAR_PAK_PATH:{
            return payload
        }
        default:
            return state;
    }
}



export const setParams=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_SEARCH_PARAMETERS:{
            console.log('params',payload)
            return payload
        }
        default:
            return state;
    }
}

export const readFileNamesReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case READ_FILE_NAMES:{

            return payload
        }
        default:
            return state;
    }
}
export const readAudioFilesReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case READ_AUDIO_FILES:{
            return payload
        }
        default:
            return state;
    }
}
export const readAudioDirReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case READ_AUDIO_DIR:{
            return payload
        }
        default:
            return state;
    }
}

export const tabValueReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_TAB_VALUE:{
            return payload
        }
        default:
            return state;
    }
}

export const advSearchExpandValueReducer=(state=true,action)=>{
    const {type,payload}=action;
    switch(type){
        case SET_ADV_SEARCH_EXPAND_VALUE:{
            return payload
        }
        default:
            return state;
    }
}

export const nohaDirTreeReducer=(state={children:[]},action)=>{
    const {type,payload}=action;
    switch(type){
        case GET_NOHA_DIRECTORY:{
            return payload
        }
        default:
            return state;
    }
}

export const tassawarPakDirTreeReducer=(state={children:[]},action)=>{
    const {type,payload}=action;
    switch(type){
        case GET_TASSAWAR_PAK_DIRECTORY:{
            return payload
        }
        default:
            return state;
    }
}


export const nohaDirMp3Reducer=(state={filenames:[]},action)=>{
    const {type,payload}=action;
    switch(type){
        case GET_NOHA_MP3:{
            return payload
        }
        default:
            return state;
    }
}

export const tasswarPakReducer=(state={filenames:[]},action)=>{
    const {type,payload}=action;
    switch(type){
        case GET_TASSAWAR_PAK:{
            return payload
        }
        default:
            return state;
    }
}

export const readBookletNamesReducer=(state=[],action)=>{
    const {type,payload}=action;
    switch(type){
        case READ_BOOKLET_NAMES:{

            return payload
        }
        default:
            return state;
    }
}
//-------------------------------------------------------------------
export default combineReducers({
    qasaid:qasaidDataReducer,
    qasaidSearchTerm:qasaidSearchTermReducer,
    qasaidSearchField:qasaidSearchFieldReducer,
    searchTerm:searchTermReducer,
    searchCategory:searchCatergoryReducer,
    imgNumber:setLogo,
    comboxValues:getComboxvaluesReducer,
    pdfs:readFileNamesReducer,
    audios:readAudioFilesReducer,
    tabValue:tabValueReducer,
    audioDirs:readAudioDirReducer,
    params:setParams,
    nohaDir:nohaDirTreeReducer,
    nohaMp3:nohaDirMp3Reducer,
    mp3Path:setMp3Path,
    tassawarPakDir:tassawarPakDirTreeReducer,
    tassawarPak:tasswarPakReducer,
    tassawarPakPath:setTassawarPakPathReducer,
    ytplaylist:ytJsonReducer,
    expandedAdvSearch:advSearchExpandValueReducer,
    booklets:readBookletNamesReducer
})