export const SET_QASAID_SEARCH_FIELD = 'SET_QASAID_SEARCH_FIELD';
export const setQasaidSearchField= (field) => {
    return {type:'SET_QASAID_SEARCH_FIELD',payload:field}
};

export const SET_QASAID_SEARCH_TERM = 'SET_QASAID_SEARCH_TERM';
export const setQasaidSearchTerm= (term) => {
    return {type:'SET_QASAID_SEARCH_TERM',payload:term}
};


export const SET_QASAID = 'SET_QASAID';
export const setQasaid= (qasaid) => {
    return {type:'SET_QASAID',payload:qasaid}
};


export const FETCH_COMBOBOXVALUES = 'FETCH_COMBOBOXVALUES';
export const fetchComboboxValues= (value,comboBoxArr) => {

    console.log(`/backend/getComboxValues?v=${value}&type=${comboBoxArr[0].type}&sinf=${comboBoxArr[1].sinf}&lang=${comboBoxArr[2].lang}&book=${comboBoxArr[3].book}&main_cat=${comboBoxArr[4].main_cat}`)
    return async (dispatch,getState) => {
            //console.log(`/backend/getComboxValues?v=${value}&type=${comboBoxArr[0].type}&sinf=${comboBoxArr[1].sinf}&lang=${comboBoxArr[2].lang}&book=${comboBoxArr[3].book}&main_cat=${comboBoxArr[4].main_cat}`)
            let comboboxValuesRes=await fetch(`/backend/getComboxValues?v=${value}&type=${comboBoxArr[0].type}&sinf=${comboBoxArr[1].sinf}&lang=${comboBoxArr[2].lang}&book=${comboBoxArr[3].book}&main_cat=${comboBoxArr[4].main_cat}`);
            
            let comboboxValuesData=await comboboxValuesRes.json();
            console.log("Fetched combo values",comboboxValuesData)
            dispatch({type:'FETCH_COMBOBOXVALUES',payload:comboboxValuesData})
    }
};

export const SEARCH_QASAID = 'SEARCH_QASAID';
export const searchQasaid= (value='',comboBoxArr=[]) => {
    return async (dispatch,getState) => {
        let qasaidRes={}
        let qasaidData=[]
        
        if(value===''){
            const {qasaidSearchTerm,searchCategory}=getState()
            console.log(`/backend/searchQasida?v=${qasaidSearchTerm}`)
            if(searchCategory==='poetry'){
                if(qasaidSearchTerm.includes('"')){
                    qasaidRes=await fetch(`/backend/searchPhrasePoetry?v=${qasaidSearchTerm}`)
                }else{
                    qasaidRes=await fetch(`/backend/searchQasida?v=${qasaidSearchTerm}`)
                }
                qasaidData=await qasaidRes.json()
            }
            else if (searchCategory==='books'){
                if(qasaidSearchTerm.includes('"')){
                    qasaidRes=await fetch(`/backend/searchPhraseBooks?v=${qasaidSearchTerm}`)
                }else{
                    qasaidRes=await fetch(`/backend/searchBook?v=${qasaidSearchTerm}`)
                }
                qasaidData=await qasaidRes.json()
            }
            else {
                if(qasaidSearchTerm.includes('"')){
                    qasaidRes=await fetch(`/backend/searchPhraseBoth?v=${qasaidSearchTerm}`)
                }else{
                    qasaidRes=await fetch(`/backend/searchBoth?v=${qasaidSearchTerm}`)
                }
                qasaidData=await qasaidRes.json()
            }
        }else{
                qasaidRes=await fetch(`/backend/searchCombo?&type=${comboBoxArr[0].type}&sinf=${comboBoxArr[1].sinf}&lang=${comboBoxArr[2].lang}&book=${comboBoxArr[3].book}&main_cat=${comboBoxArr[4].main_cat}`);
                qasaidData=await qasaidRes.json();
        }
        console.log('Qasaid Action',qasaidData)
        dispatch({type:'SEARCH_QASAID',payload:qasaidData})
    }
};

export const SEARCH_QASAID_SORTED = 'SEARCH_QASAID_SORTED';
export const searchQasaidSorted= (value='',comboBoxArr=[]) => {
    return async (dispatch,getState) => {
        let qasaidRes={}
        let qasaidData=[]
        
        if(value===''){
            const {qasaidSearchTerm,searchCategory}=getState()

            console.log(`/backend/searchQasida?v=${qasaidSearchTerm}`)
            if(searchCategory==='poetry'){
                qasaidRes=await fetch(`/backend/searchQasida?v=${qasaidSearchTerm}`)
                qasaidData=await qasaidRes.json()
            }
            else if (searchCategory==='books'){
                qasaidRes=await fetch(`/backend/searchBook?v=${qasaidSearchTerm}`)
                qasaidData=await qasaidRes.json()
            }
            else {
                qasaidRes=await fetch(`/backend/searchBoth?v=${qasaidSearchTerm}`)
                qasaidData=await qasaidRes.json()
            }
        }else{
                qasaidRes=await fetch(`/backend/searchCombo?&type=${comboBoxArr[0].type}&sinf=${comboBoxArr[1].sinf}&lang=${comboBoxArr[2].lang}&book=${comboBoxArr[3].book}&main_cat=${comboBoxArr[4].main_cat}`);
                qasaidData=await qasaidRes.json();
        }
        console.log('Qasaid Action',qasaidData)
        dispatch({type:'SEARCH_QASAID',payload:qasaidData})
    }
};

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const setSearchTerm= (term) => {
    return {type:'SET_SEARCH_TERM',payload:term}
};

export const SET_SEARCH_CATEGORY = 'SET_SEARCH_CATEGORY';
export const setSearchCategory= (term) => {
    return {type:'SET_SEARCH_CATEGORY',payload:term}
};

export const LOGO_URL = 'LOGO_URL';
export const setLogo= () => {
    return {type:'LOGO_URL',payload:Math.floor(Math.random() * 20)}
};

export const READ_FILE_NAMES = 'READ_FILE_NAMES';
export const readFileNames= () => {
    return async (dispatch,getState) => {
        let fienameRes={}
        let fienameData=[]

        fienameRes=await fetch(`/backend/readfiles?p=/home/jamanshah/crj2/pdf-books/title`);
        //console.log('Resp',fienameRes)
        fienameData=await fienameRes.json()
        //console.log('Action Data',fienameData)
        dispatch({type:'READ_FILE_NAMES',payload:fienameData})
        return {type:'READ_FILE_NAMES',payload:fienameData}
    }
};

export const SET_TAB_VALUE = 'SET_TAB_VALUE';
export const setTabValue= (term) => {
    return {type:'SET_TAB_VALUE',payload:term}
};

export const SET_ADV_SEARCH_EXPAND_VALUE = 'SET_ADV_SEARCH_EXPAND_VALUE';
export const setAdvSearchExpandValue= (term) => {
    return {type:'SET_ADV_SEARCH_EXPAND_VALUE',payload:term}
};

export const READ_AUDIO_FILES = 'READ_AUDIO_FILES';
export const readAudioFiles= (path) => {
    return async (dispatch,getState) => {
        let audioRes={}
        let audioData=[]

        audioRes=await fetch(`/backend/readfiles?p=${path}`);
        console.log('Resp',audioRes)
        audioData=await audioRes.json()
        console.log('Action Data',audioData)
        dispatch({type:'READ_AUDIO_FILES',payload:audioData})
        return {type:'READ_AUDIO_FILES',payload:audioData}
    }
};

export const READ_AUDIO_DIR='READ_AUDIO_DIR';
export const readAudioDir=()=>{
    return async(dispatch,getState)=>{
        let dirRes={}
        let dirs=[]

        dirRes=await fetch('/backend/readDir?p=/home/jamanshah/crj2/audio-books/')
        dirs=await dirRes.json()
        dispatch({type:'READ_AUDIO_DIR',payload:dirs})
        return({type:'READ_AUDIO_DIR',payload:dirs})
    }
    
}

export const SET_SEARCH_PARAMETERS='SET_SEARCH_PARAMETERS';
export const setSearchParams=(params=[])=>{
        return({type:'SET_SEARCH_PARAMETERS',payload:params})
}

export const GET_SORTED_RESULTS='GET_SORTED_RESULTS';
export const getSortedResults=(col)=>{
    return async(dispatch,getState)=>{
        const {params}=getState()
        console.log('getiing',params)

        console.log(`/backend/getSortedResults?searchtype=${params[0]}&term=${params[1]}&type=${params[2][0].type}&sinf=${params[2][1].sinf}&lang=${params[2][2].lang}&book=${params[2][3].book}&main_cat=${params[2][4].main_cat}&col=${col}`)
        let qasaidRes={}
        let qasaidData=[]
        qasaidRes=await fetch(`/backend/getSortedResults?searchtype=${params[0]}&term=${params[1]}&type=${params[2][0].type}&sinf=${params[2][1].sinf}&lang=${params[2][2].lang}&book=${params[2][3].book}&main_cat=${params[2][4].main_cat}&col=${col}`);
        qasaidData=await qasaidRes.json()
        dispatch({type:'GET_SORTED_RESULTS',payload:qasaidData})
        return({type:'GET_SORTED_RESULTS',payload:qasaidData})
    }
}


export const GET_NOHA_DIRECTORY='GET_NOHA_DIRECTORY';
export const getNohaDirectory=()=>{
    return async(dispatch)=>{
        let nohaDirRes={}
        let nohaDirTree=[]
        nohaDirRes=await fetch(`/backend/getDirStructNoha`);
        nohaDirTree=await nohaDirRes.json()
        dispatch({type:'GET_NOHA_DIRECTORY',payload:nohaDirTree})
        return({type:'GET_NOHA_DIRECTORY',payload:nohaDirTree})
    }
}

export const GET_NOHA_MP3='GET_NOHA_MP3';
export const getNohaMp3=(path)=>{
    return async(dispatch)=>{
        let nohaMp3Res={}
        let nohaMp3=[]
        nohaMp3Res=await fetch(`/backend/readNohaMp3?path=${encodeURIComponent(path)}`);
        nohaMp3=await nohaMp3Res.json()
        dispatch({type:'GET_NOHA_MP3',payload:nohaMp3})
        return({type:'GET_NOHA_MP3',payload:nohaMp3})
    }
}

export const SET_MP3_PATH='SET_MP3_PATH';
export const setMp3Path=(path='')=>{
        return({type:'SET_MP3_PATH',payload:path})
}

export const GET_TASSAWAR_PAK_DIRECTORY='GET_TASSAWAR_PAK_DIRECTORY';
export const getTassawarPakDirectory=()=>{
    return async(dispatch)=>{
        let nohaDirRes={}
        let nohaDirTree=[]
        nohaDirRes=await fetch(`/backend/getTassawarPakDirStruct`);
        nohaDirTree=await nohaDirRes.json()
        dispatch({type:'GET_TASSAWAR_PAK_DIRECTORY',payload:nohaDirTree})
        return({type:'GET_TASSAWAR_PAK_DIRECTORY',payload:nohaDirTree})
    }
}

export const GET_TASSAWAR_PAK='GET_TASSAWAR_PAK';
export const getTassawarPak=(path)=>{
    return async(dispatch)=>{
        let nohaMp3Res={}
        let nohaMp3=[]
        nohaMp3Res=await fetch(`/backend/readTassawarPak?path=${encodeURIComponent(path)}`);
        nohaMp3=await nohaMp3Res.json()
        dispatch({type:'GET_TASSAWAR_PAK',payload:nohaMp3})
        return({type:'GET_TASSAWAR_PAK',payload:nohaMp3})
    }
}

export const SET_TASSAWAR_PAK_PATH='SET_TASSAWAR_PAK_PATH';
export const setTassawarPakPath=(path='')=>{
        return({type:'SET_TASSAWAR_PAK_PATH',payload:path})
}

export const GET_YT_JSON='GET_YT_JSON';
export const getYtJson=()=>{
    return async(dispatch)=>{
        let nohaMp3Res={}
        let nohaMp3=[]
        nohaMp3Res=await fetch(`/crj2/json/youtubeplaylist.json`);
        nohaMp3=await nohaMp3Res.json()
        dispatch({type:'GET_YT_JSON',payload:nohaMp3})
        return({type:'GET_YT_JSON',payload:nohaMp3})
    }
}

export const READ_BOOKLET_NAMES = 'READ_BOOKLET_NAMES';
export const readBookletNames= () => {
    return async (dispatch,getState) => {
        let fienameRes={}
        let fienameData=[]
        fienameRes=await fetch(`/backend/readfiles?p=/home/jamanshah/crj2/booklets(kitabchy)`);
        //console.log('Resp',fienameRes)
        fienameData=await fienameRes.json()
        //console.log('Action Data',fienameData)
        dispatch({type:'READ_BOOKLET_NAMES',payload:fienameData})
        return {type:'READ_BOOKLET_NAMES',payload:fienameData}
    }
};